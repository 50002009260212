import React from "react";
import { Typography } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

interface Props {
  email?: string;
  runTime?: string;
  isReady?: boolean;
  testIsLoading?: boolean;
  uuid?: string;
  classes?: any;
}

const Information = ({ email, runTime, classes, isReady, testIsLoading, uuid }: Props) => {
  const { t } = useTranslation(["tests"]);

  return (
    <div className={classes.insightInfo}>
      <Typography variant="body2">
        {t("tests:resultPage.user-details")}: Email: {email} | {t("tests:resultPage.run-time")}:{" "}
        {isReady && !testIsLoading && uuid && (
          <>{format(parseISO(runTime ? runTime : new Date().toISOString()), "MM-dd-yyyy, HH:mm")}</>
        )}
      </Typography>
    </div>
  );
};

export default Information;
