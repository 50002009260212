import React from "react";
import { IPing, ITestsResult } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import WidgetHeader from "./UI/WidgetHeader";
import { withTheme } from "@material-ui/core";
import ErrorState from "./UI/ErrorState";

interface IPingTestAllProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  classes?: any;
  pageSize?: number;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: any = [];

const NewPingTestAll = (props: IPingTestAllProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime } = props;
  const pingTest: any = testResult && testResult.pingTest ? testResult.pingTest.all : initialValues;
  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const error = (pingTest as any).error;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };

  const { t } = useTranslation(["common", "tests"]);

  const getColor = (value: number | string | null, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  return (
    <>
      <WidgetHeader
        title={t("tests:pingtestall.title")}
        tooltip={t("tests:pingtestall.tooltip")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <>
          <div className="widget-ping-all-wrapper">
            <div className="widget-ping-all-sticky-row">
              <div className="sticky-row">
                <p>Region</p>
                <p>RTT</p>
              </div>
            </div>
            <div className="widget-ping-all-scroll-content">
              {pingTest.map((test: IPing, i: number) => {
                let region: any = test.region;
                if (region && region.match(/\(.+\)/)) {
                  region = region.replace("(", "").replace(")", "");
                }
                return (
                  <div className="ping-all-row" id={`PBT-r-ping ${i}`}>
                    <p>{region || "Unknown"}</p>
                    <p
                      style={{ color: getColor(test.ping, currentThresholds?.ping) }}
                      id={`PBT-c-ping ${i}`}
                    >
                      {test.ping === -1 ? "-" : formatNumber(Number(test.ping)) || "Unknown"}{" "}
                      <span>ms</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="ping-all-overlay" />
        </>
      )}
    </>
  );
};

export default withTheme()(NewPingTestAll);
