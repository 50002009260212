import React, { ReactNode } from "react";
import { withStyles } from "@material-ui/core";

interface CardContainer {
  children: ReactNode;
  id: string;
  classes: any;
}

const styles = (theme: any): any => ({
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    background: theme.newPalette.cardBackground,
    minWidth: "300px",
    width: "383px",
    maxWidth: "669px",
    height: "310px",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
  },
});

const CardContainer = ({ children, id, classes }: CardContainer) => {
  return (
    <div className={`${classes.card} parent-card`} id={id}>
      {children}
    </div>
  );
};

export default withStyles(styles)(CardContainer);
