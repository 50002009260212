import React from "react";
import Banner from "./Banner";
import { InfoIcon, PendingActionsIcon } from "./Icons";
import "./Widget.css";
import { Tooltip } from "@material-ui/core";
import { ITestsResult } from "twillio-tests/core/TestResults";
import { LogModalContext } from "../../../../LogModal/LogModalProvider";

interface WidgetHeaderProps {
  title: string;
  tooltip: string;
  estimateTime?: number;
  knowledgeUrl?: string;
  error?: string;
  badge?: string;
  testResult?: ITestsResult;
}

const WidgetHeader = ({
  title,
  tooltip,
  estimateTime,
  knowledgeUrl,
  testResult,
  error,
  badge,
}: WidgetHeaderProps) => {
  const logModalContext = React.useContext(LogModalContext);

  const tooltipElem = (
    <>
      <div className="arrow" />
      {tooltip}
      {estimateTime && (
        <>
          <br />
          {`${estimateTime}s`}
        </>
      )}
    </>
  );

  return (
    <div className="widgetHeader-wrapper">
      {/* Custom border color  */}
      <div className="widgetHeader-container">
        <div className="widgetHeader-title">
          <h3 id={title.split(" ").join("")}>{title}</h3>
          {badge && <Banner title={badge} />}
        </div>
        <div className="widgetHeader-icons">
          <Tooltip title={tooltipElem} placement="top">
            {knowledgeUrl !== "-" ? (
              <a href={knowledgeUrl} target="_blank" rel="noopener noreferrer">
                <InfoIcon />
              </a>
            ) : (
              <InfoIcon />
            )}
          </Tooltip>
          {testResult && (
            <button
              onClick={() => logModalContext.setOpen(true, title)}
              className={`${
                testResult && "widgetHeader-badge-container-clickable"
              } widgetHeader-badge-container`}
            >
              <PendingActionsIcon />
              {error && <div className="widgetHeader-error-badge" />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetHeader;
