/*global chrome*/
import React, { useEffect, useState } from "react";

const useExtensionTracker = () => {
  const userAgent = navigator.userAgent.includes("Chrome");

  const [state, setState] = useState(true);
  const [isInstalled, setIsInstalled] = useState(false);
  useEffect(() => {
    checkAndSetStatus();
  }, []);

  useEffect(() => {
    if (isInstalled) {
      setState(true);
    } else {
      setState(false);
    }
  }, [isInstalled]);

  useEffect(() => {
    if (userAgent && chrome && chrome.runtime) {
      checkAndSetStatus();
    }
  }, [userAgent]);

  const checkAndSetStatus = () => {
    if (userAgent && chrome && chrome.runtime) {
      // Send a message to the extension to check if it's installed
      chrome.runtime.sendMessage(
        "ninnkfgcogbifinffmalccnoijlhhnok",
        {
          action: "GET_EXTENSION_INFO",
        },
        (response) => {
          if (!chrome.runtime.lastError) {
            console.log(response);
            setIsInstalled(true);
          } else {
            setIsInstalled(false);
          }
        }
      );
    } else {
      setIsInstalled(false);
    }
  };
  return state;
};
export default useExtensionTracker;
