import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import withTheme from "@material-ui/core/styles/withTheme";
import { Button } from "@material-ui/core";

const styles = (theme: any): any => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 444,
    width: "100%",
    backgroundColor: "#fff",
    padding: "20px 16px 10px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    minHeight: "150px",
    display: "flex",
    alignItems: "center",
  },
  newPaper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "16px 24px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    minHeight: "190px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "16px",
  },
  content: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "stretch" as "stretch",
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  field: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 3,
    width: "calc(100%-40px)",
  },
  error: {
    margin: 0,
    width: "100%",
    textAlign: "center" as "center",
    fontSize: "1.3rem",
    color: "red",
  },
  button: {
    minWidth: 200,
    alignSelf: "center" as "center",
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  expiredTitle: {
    paddingBottom: "16px",
    color: theme.newPalette.text.primary,
  },
  expiredMessage: {
    padding: "8px 0",
    color: theme.newPalette.text.primary,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    background: theme.newPalette.testrtc.primary,
    padding: "6px 16px",
  },
});

const MessageModal = (props: any) => {
  const [open, setOpen] = useState(false);
  const { config } = props;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (config?.invalidInvite) {
      setOpen(true);
    }
  }, [config?.invalidInvite]);

  const { classes } = props;

  if (config?.useNewWidgetDesign) {
    return (
      <Modal
        open={open}
        BackdropProps={{
          style: { background: "rgba(36, 53, 68, 0.5)" },
        }}
      >
        <div className={classes.newPaper}>
          <div className={classes.messageContainer}>
            <Typography variant="h3" className={classes.expiredTitle}>
              Link Expired
            </Typography>
            <Typography variant="body1" className={classes.expiredMessage}>
              {config?.invalidInviteMessage}
            </Typography>
          </div>
          <div className={classes.btnContainer}>
            <Button className={classes.btn} onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open}>
      <div className={classes.paper}>
        {/* <span className={classes.close} onClick={props.handleClose}>
          Close
        </span> */}
        <Typography variant="h5" className={classes.error}>
          {config?.invalidInviteMessage}
        </Typography>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(withTheme()(MessageModal));
