import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { VideoQualityIco, VideoP2PIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IVideoP2P, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import ResultRow from "./UI/ResultRow";

interface IVideoP2PTestTest {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  videoQualityMode?: boolean;
  testName?: string;
  config?: TestConfiguration;
  classes: any;
}

const initialValues: IVideoP2P = {
  bandwidthEstimateIn: undefined,
  bandwidthEstimateOut: undefined,

  bitrate: undefined,
  bitrateOut: undefined,

  roundTripTime: undefined,

  fractionLoss: undefined,
  fractionLossOut: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    height: "100%",
    "& > :not(:first-child)": {
      borderRightWidth: "0px",
      borderLeftWidth: "1px",
      borderLeft: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  inParentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "19px",
    height: "100%",
    paddingRight: "7px",
  },
  outParentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "19px",
    height: "100%",
    paddingLeft: "7px",
  },
  inResultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
    height: "100%",
  },
  outResultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
    height: "100%",
  },
  resultssContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minWidth: "167px",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
});

const VideoP2PTest = (props: IVideoP2PTestTest) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    videoQualityMode,
    testName,
    config,
    classes,
  } = props;

  const results =
    (testResult && testResult[videoQualityMode ? "videoQualityTest" : "videoP2P"]) || initialValues;

  const currentThresholds = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoP2P;

  const {
    error,
    bandwidthEstimateOut,
    bitrate,
    bitrateOut,
    roundTripTime,
    fractionLoss,
    fractionLossOut,
    meta,
  } = results;

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign
      ? theme.newPalette.text.primary
      : theme.customColors.successColor,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign
      ? theme.newPalette.error.main
      : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign
      ? theme.newPalette.warning.main
      : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign
      ? theme.newPalette.text.primary
      : theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = videoQualityMode ? t("tests:video.quality") : t("tests:video.p2p");

  return (
    <TestHeader
      icon={videoQualityMode ? <VideoQualityIco /> : <VideoP2PIco />}
      title={title}
      tooltip={title}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      errorsCount={meta?.errorsCount}
      badge={
        videoQualityMode
          ? testResult?.videoQualityTest?.transportProtocol
          : testResult?.videoP2P?.transportProtocol
      }
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.inParentContainer}>
            <Typography variant="body1" className={classes.titleColor}>
              {t("in")}
            </Typography>
            <div className={classes.inResultContainer}>
              <ResultRow label={t("estimated")} value="-" />
              <ResultRow
                label={t("bitrate")}
                value={bitrate}
                unit="kbps"
                color={
                  currentThresholds && currentThresholds.bitrate
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: bitrate,
                          warningValue: currentThresholds.bitrate.warning,
                          errorValue: currentThresholds.bitrate.error,
                        },
                        currentThresholds.bitrate.isLess
                      )
                    : defaultProps.color
                }
              />
              <ResultRow label={t("Round Trip")} value="-" />
              <ResultRow
                label={t("Packet Loss")}
                value={fractionLoss}
                unit="%"
                color={
                  currentThresholds && currentThresholds.fractionLoss
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: fractionLoss,
                          warningValue: currentThresholds.fractionLoss.warning,
                          errorValue: currentThresholds.fractionLoss.error,
                        },
                        currentThresholds.fractionLoss.isLess
                      )
                    : defaultProps.color
                }
              />
            </div>
          </div>
          <div className={classes.outParentContainer}>
            <Typography variant="body1" className={classes.titleColor}>
              {t("out")}
            </Typography>
            <div className={classes.outResultContainer}>
              <ResultRow
                label={t("estimated")}
                value={bandwidthEstimateOut}
                unit="kbps"
                color={
                  currentThresholds && currentThresholds.bandwidthEstimateOut
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: bandwidthEstimateOut,
                          warningValue: currentThresholds.bandwidthEstimateOut.warning,
                          errorValue: currentThresholds.bandwidthEstimateOut.error,
                        },
                        currentThresholds.bandwidthEstimateOut.isLess
                      )
                    : defaultProps.color
                }
              />
              <ResultRow
                label={t("bitrate")}
                value={bitrateOut}
                unit="kbps"
                color={
                  currentThresholds && currentThresholds.bitrateOut
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: bitrateOut,
                          warningValue: currentThresholds.bitrateOut.warning,
                          errorValue: currentThresholds.bitrateOut.error,
                        },
                        currentThresholds.bitrateOut.isLess
                      )
                    : defaultProps.color
                }
              />
              <ResultRow
                label={t("Round Trip")}
                value={roundTripTime}
                unit="ms"
                color={
                  currentThresholds && currentThresholds.roundTripTime
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: roundTripTime,
                          warningValue: currentThresholds.roundTripTime.warning,
                          errorValue: currentThresholds.roundTripTime.error,
                        },
                        currentThresholds.roundTripTime.isLess
                      )
                    : defaultProps.color
                }
              />
              <ResultRow
                label={t("Packet Loss")}
                value={fractionLossOut}
                unit="%"
                color={
                  currentThresholds && currentThresholds.fractionLossOut
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: fractionLossOut,
                          warningValue: currentThresholds.fractionLossOut.warning,
                          errorValue: currentThresholds.fractionLossOut.error,
                        },
                        currentThresholds.fractionLossOut.isLess
                      )
                    : defaultProps.color
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--throughput tst-throughput">
          <table>
            <thead>
              <tr>
                <th />
                <td>{t("in")}</td>
                <td>{t("out")}</td>
              </tr>
            </thead>
            <tbody>
              <tr id={`${testName}-r-estimated`}>
                <td>{t("estimated")}</td>
                <td></td>
                <td id={`${testName}-c-bandwidthEstimateOut`}>
                  <RenderValues
                    {...defaultProps}
                    value={bandwidthEstimateOut}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>kbps</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.bandwidthEstimateOut
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: bandwidthEstimateOut,
                              warningValue: currentThresholds.bandwidthEstimateOut.warning,
                              errorValue: currentThresholds.bandwidthEstimateOut.error,
                            },
                            currentThresholds.bandwidthEstimateOut.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr id={`${testName}-r-bitrate`}>
                <td>{t("bitrate")}</td>
                <td id={`${testName}-c-bitrate`}>
                  <RenderValues
                    {...defaultProps}
                    value={bitrate}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>kbps</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.bitrate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: bitrate,
                              warningValue: currentThresholds.bitrate.warning,
                              errorValue: currentThresholds.bitrate.error,
                            },
                            currentThresholds.bitrate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                <td id={`${testName}-c-bitrateOut`}>
                  <RenderValues
                    {...defaultProps}
                    value={bitrateOut}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>kbps</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.bitrateOut
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: bitrateOut,
                              warningValue: currentThresholds.bitrateOut.warning,
                              errorValue: currentThresholds.bitrateOut.error,
                            },
                            currentThresholds.bitrateOut.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr id={`${testName}-r-roundTrip`}>
                <td>{t("Round Trip")}</td>
                <td></td>
                <td id={`${testName}-c-roundTripTime`}>
                  <RenderValues
                    {...defaultProps}
                    value={roundTripTime}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>ms</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.roundTripTime
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: roundTripTime,
                              warningValue: currentThresholds.roundTripTime.warning,
                              errorValue: currentThresholds.roundTripTime.error,
                            },
                            currentThresholds.roundTripTime.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr id={`${testName}-r-packetLoss`}>
                <td>{t("Packet Loss")}</td>
                <td id={`${testName}-c-fractionLoss`}>
                  <RenderValues
                    {...defaultProps}
                    value={fractionLoss}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>%</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.fractionLoss
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: fractionLoss,
                              warningValue: currentThresholds.fractionLoss.warning,
                              errorValue: currentThresholds.fractionLoss.error,
                            },
                            currentThresholds.fractionLoss.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                <td id={`${testName}-c-fractionLossOut`}>
                  <RenderValues
                    {...defaultProps}
                    value={fractionLossOut}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>%</span>`
                    }
                    color={
                      currentThresholds && currentThresholds.fractionLossOut
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: fractionLossOut,
                              warningValue: currentThresholds.fractionLossOut.warning,
                              errorValue: currentThresholds.fractionLossOut.error,
                            },
                            currentThresholds.fractionLossOut.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(VideoP2PTest));
