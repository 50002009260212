import React from "react";
import "./Widget.css";

interface BannerProps {
  title: string;
}

const Banner = ({ title }: BannerProps) => {
  return <button className="banner">{title}</button>;
};

export default Banner;
