import React, { Component } from "react";
import { connect } from "react-redux";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";

import { TestNames } from "twillio-tests/config";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { Estimates } from "twillio-tests/estimates";
import { ITestsResult } from "twillio-tests/core/TestResults";

import ThroughputTest from "./Tests/ThroughputTest";
import CallQualityTest from "./Tests/CallQualityTest";
import NetworkInformation from "./Tests/NetworkInformationTest";
import TurnConnectivityTest from "./Tests/TurnConnectivityTest";
import LocationTest from "./Tests/LocationTest";
import LocationDetailedWidget from "./Tests/LocationDetailedWidget";
import BandwidthBestTest from "./Tests/BandwidthBestTest";
import BandwidthTestAll from "./Tests/BandwidthTestAll";
import PingTest from "./Tests/PingBestTest";
import PingTestAll from "./Tests/PingTestAll";
import VideoBandwidthTest from "./Tests/VideoBandwidthTest";
import DNSLookupTest from "./Tests/DNSLookupTest";
import RCVTURNServersTest from "./Tests/RCVTURNServersTest";
import VideoP2PTest from "./Tests/VideoP2PTest";
import DeviceStateTest from "./Tests/DeviceStateTest";

import "./TestsContainer.scss";
import BrowserSupportModal from "../BrowserSupportModal/";
import MessageModal from "../MessageModal/MessageModal";
import PrivateConnectionsTest from "./Tests/PrivateConnectionsTest";
import CardContainer from "./Tests/NewTests/UI/CardContainer";
import NewPingTestAll from "./Tests/NewTests/NewPingTestAll";
import NewPingTest from "./Tests/NewTests/NewPingTest";
import HardwareAvailabilityTest from "./Tests/HardwareAvailabilityTest";

function mapStateToProps(state: any) {
  return {
    testsList: state.tests.testsList,
    testResult: state.tests.testResult,
    logs: state.tests.logs,
    config: state.tests.config,
    colorThresholds: state.document.colorThresholds,
    currentTheme: state.document.theme,
  };
}

const styles = (theme: any) => ({
  container: {
    padding: "0",
    display: "flex",
    flexWrap: "wrap" as "wrap",

    justifyContent: "center",
    gap: "16px",
  },
  cardItem: {
    width: 383,
    "& td": {
      fontWeight: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  cardItem_mend: {
    color: "rgb(40, 40, 40)",
    fontFamily: "'Poppins', sans-serif !important",
    "& td": {
      fontWeight: 500,
      "& span": {
        fontWeight: 500,
      },
    },
  },
  cardItem_amazon: {
    "& td": {
      fontWeight: 400,
      "& span": {
        fontWeight: 400,
      },
    },
  },

  cardItem_avatour: {
    "& td": {
      fontWeight: 600,
      color: "#fff",
    },
  },

  card: {
    background: "#fff",
    border: "1px solid #c9cbd1",
    borderRadius: 3,
    padding: 16,
    color: theme.palette.secondary.main,
    minHeight: 250,
    display: "flex",
    flexDirection: "column" as "column",
    overflowX: "hidden" as "hidden",
    height: "100%",
  },
  card_freshworks: {
    color: theme.palette.primary.dark,
    "& td": {
      fontWeight: 500,
    },
  },
  card_logitech: {
    background: "white",
  },
  card_nexmo: {
    borderRadius: 8,
    border: "1px solid #EFEFEE",
  },
  card_glia: {
    borderRadius: 8,
    boxShadow: "0 24px 32px 0 rgb(0 0 0 / 12%)",
    color: "#2c0735",
  },
  card_nice: {
    border: "none",
  },
  card_teladochealth: {
    border: "none",
  },
  card_mend: {
    border: "none",
    borderRadius: 0,
  },
  card_cxengage: {
    border: "none",
    borderRadius: 0,
  },
  card_lifesize: {
    border: "none",
    borderRadius: 0,
  },
  card_livevox: {
    background: "#ffffff",
    borderRadius: "5px 5px 5px 5px",
    borderWidth: 0,
    boxShadow: "0px 12px 18px -6px rgb(0 0 0 / 30%)",
  },
  card_itracks: {
    border: "none",
    borderRadius: 0,
    backgroundColor: "rgb(246, 246, 246)",
  },
  card_navis: {
    border: "0 solid #333",
    borderTopWidth: 2,
    borderTopColor: "#b56ea6",
    boxShadow: "0 3px 10px -5px rgba(0,0,0,.6)",
    borderRadius: 7,
  },
  card_digit: {
    boxShadow: "0 1px 3px rgba(9,11,13,.04), 0 0 8px rgba(9,11,13,.04)",
    borderRadius: 5,
  },
  "card_extcare-webex": {
    border: "none",
  },
  card_amazon: {
    borderColor: "#EAEDED",
    borderRadius: 5,
    borderStyle: "bold",
  },
  card_engauge: {
    border: "1px solid #666666",
    borderRadius: 0,
  },
  card_salesloft: {
    background: "#F2F2F2",
  },
  card_avatour: {
    borderRadius: 15,
    border: "none",
    backgroundColor: "#0d1922",
    color: "#fff",
  },
  card_zaurus: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 18px 0px rgba(0,0,0,0.3)",
    "& td": {
      fontWeight: 500,
      "& span": {
        fontWeight: 500,
      },
    },
  },
  cardItem_puzzel: {
    fontFamily: "Open Sans, sans-serif",
  },
  card_togetherseniorhealth: {
    border: "none",
  },
  card_dialpad: {
    "& #TCT-title": {
      fontWeight: 500,
      fontSize: "15px",
    },
    border: "none",
    backgroundColor: "#F5F2ED",
    "& th": {
      fontWeight: 500,
      fontSize: "15px",
    },
    "& td": {
      fontWeight: 500,
      fontSize: "15px",
      "& span": {
        fontWeight: 500,
        fontSize: "15px",
      },
    },
    "& span": {
      "& >span": {
        fontWeight: 500,
        fontSize: "15px",
      },
    },
  },
  "card_talview-videocall": {
    color: "#515978!important",
  },
});

interface ITestsContainerProps {
  testsList?: string[];
  classes?: any;
  theme: any;
  config?: TestConfiguration;
  testResult?: ITestsResult;
  colorThresholds?: IColorThresholds;
  currentTheme?: string;
  logs?: any;
}

const getEstimate = (config: TestConfiguration | undefined, test: string) => {
  if (config) {
    if (test === "VideoQuality" && (config as any).VideoQuality?.ConnectionInfoName === "webex") {
      return Estimates["WebexVideoQuality"];
    }
    if (test === "DNSLookup") {
      return config.domains?.length ? (config.domains.length * 200) / 1000 : 0;
    }
  }
  return Estimates[test];
};

const TestsContainer = (props: ITestsContainerProps) => {
  const renderTests = () => {
    const { theme, classes, config, testsList = [], currentTheme, testResult } = props;
    let userSelectedRunResult = testResult?.testRunningSequence?.initial; // selecting test that are run by user by params
    // let userSelectedRunResult = logs[0]?.message["URL query parameters"]?.["run"];
    const showTestslist = userSelectedRunResult ? userSelectedRunResult : testsList;
    return showTestslist
      .sort((a: string, b: string) => testsList.indexOf(a) - testsList.indexOf(b))
      .filter((test: string) => {
        if (config && config[test]?.hidden && config?.isClientPortal) {
          return false;
        } else {
          return test;
        }
      })
      .map((test: string) => {
        const testName: TestNames = TestNames[test as keyof typeof TestNames];
        let element;
        const defaultProps = {
          pageSize: config?.pageSize || 3,
          mainColor: theme.palette.primary.main,
          testResult: props.testResult,
          colorThresholds: props.colorThresholds,
          knowledgeUrl: config?.knowledgeUrl?.[test],
          estimateTime: getEstimate(config, test),
          testName: test,
          testTitle: config && config[test] ? config[test].title : false,
          currentTheme: currentTheme,
          useNewWidgetDesign: config?.useNewWidgetDesign,
          logs: props.logs,
        };
        const wrapElements = (elements: any, someKey?: string) => {
          if (defaultProps.useNewWidgetDesign) {
            return (
              <CardContainer key={`${testName} ${someKey}`} id={`id_${test}`}>
                {elements}
              </CardContainer>
            );
          }
          return (
            <div
              className={`${classes.cardItem} ${classes[`cardItem_${test}`]}  ${
                classes[`cardItem_${currentTheme}`]
              } widget-class`}
              key={`${testName} ${someKey}`}
            >
              <div
                className={`${classes.card} ${classes[`card_${currentTheme}`]} ${classes[`card_${test}`]}`}
                id={`id_${test}`}
              >
                {elements}
              </div>
            </div>
          );
        };

        switch (testName) {
          case TestNames.Throughput:
            element = <ThroughputTest {...defaultProps} config={config} />;
            break;
          case TestNames.CallQuality:
            element = <CallQualityTest {...defaultProps} config={config} />;
            break;
          case TestNames.TurnConnectivity:
            if (!config?.TurnConnectivity?.hide) {
              element = <TurnConnectivityTest {...defaultProps} config={config} />;
            } else {
              element = null;
            }
            break;
          case TestNames.Location:
            const showInMap =
              (config as any).Location?.showInMap === undefined ? true : (config as any).Location?.showInMap;
            const widgetVariant = (config as any).location?.widgetType;
            defaultProps.useNewWidgetDesign
              ? (element = <LocationTest config={config} {...defaultProps} showInMap={showInMap} />)
              : (element =
                  widgetVariant === "detailed" ? (
                    <LocationDetailedWidget {...defaultProps} showInMap={showInMap} />
                  ) : (
                    <LocationTest config={config} {...defaultProps} showInMap={showInMap} />
                  ));

            break;
          case TestNames.BandwidthSpeed:
            element = (config as any).BandwidthSpeed?.all ? (
              <BandwidthTestAll
                {...defaultProps}
                onlyOneItem={config?.speedAllList && config.speedAllList.length === 1}
              />
            ) : (
              <BandwidthBestTest {...defaultProps} config={config} />
            );
            break;
          case TestNames.Ping:
            if (!config?.ping?.hide) {
              element = (config as any)?.Ping?.all ? (
                defaultProps.useNewWidgetDesign ? (
                  <NewPingTestAll {...defaultProps} />
                ) : (
                  <PingTestAll {...defaultProps} />
                )
              ) : defaultProps.useNewWidgetDesign ? (
                <NewPingTest {...defaultProps} />
              ) : (
                <PingTest {...defaultProps} />
              );
            } else {
              element = null;
            }
            break;
          case TestNames.VideoBandwidth:
            element = <VideoBandwidthTest {...defaultProps} config={config} />;
            break;
          case TestNames.VideoBandwidth2:
            element = <VideoBandwidthTest {...defaultProps} config={config} />;
            break;
          case TestNames.VideoP2P:
            if (!config?.VideoP2P?.hidden) {
              element = <VideoP2PTest {...defaultProps} config={config} />;
            } else {
              element = null;
            }
            break;
          case TestNames.VideoQuality:
            element = <VideoP2PTest {...defaultProps} videoQualityMode={true} config={config} />;
            break;
          case TestNames.DNSLookup:
            element = <DNSLookupTest {...defaultProps} config={config} />;
            break;
          case TestNames.RCVTURNServers:
            element = <RCVTURNServersTest {...defaultProps} />;
            break;
          case TestNames.PrivateConnections:
            element = <PrivateConnectionsTest {...defaultProps} />;
            break;
          case TestNames.DeviceState:
            element = <DeviceStateTest {...defaultProps} config={config} />;
            break;
          case TestNames.HardwareAvailability:
            if (config?.HardwareAvailability?.show) {
              element = <HardwareAvailabilityTest {...defaultProps} />;
            } else {
              element = null;
            }
            break;
          default:
            element = null;
            break;
        }
        if (element === null) {
          return element;
        }
        return wrapElements(element);
      });
  };
  const { config } = props;
  return (
    <div className={props.classes.container}>
      <>
        {renderTests()}
        <BrowserSupportModal />
        <MessageModal config={config} />
      </>
    </div>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(withTheme()(TestsContainer)));
