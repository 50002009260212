import React from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "@material-ui/core";
interface IPermissionModalProps {
  classes?: any;
  theme?: any;
  open: boolean;
}
function mapStateToProps(state: any) {
  return {
    theme: state.document.theme,
  };
}
const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 600,
    width: "90%",
    backgroundColor: "#fff",
    padding: "30px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    textAlign: "center" as "center",
  },
  companionModalHeader: {
    marginBottom: '10px',
    fontSize: '20px'
  },
  companionModalDescription: {
    marginBottom: '10px',
    fontWeight: 400,
  
  },
  companionModalBtns: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    paddingTop: 16

  },

  gifClass: {
    textAlign: "center" as "center",
  },

  gifImage: {
    maxWidth: '400px', height: 'auto',  
    borderColor: '#5471891F',
    borderStyle: 'solid', borderWidth: 2,    display: 'inline-block'
  },

  reloadBtn: {
    background: 'none !important',
    color: '#547189',
  }


});
const CompanionModal = (props: IPermissionModalProps) => {
  const { classes, theme, open } = props;
  return (
    <Modal open={open}>
      <div className={classes.paper}>
        <div className={`${classes.cont}`}>
          <div className="companion-modal">
            <h1 className={classes.companionModalHeader}>Install the testRTC companion chrome extension.</h1>
            <div className={classes.companionModalDescription}>
              <span >
                {" "}
                To run a test and access performance insights, please install the extension. 
              </span>
            </div>
            <div className={classes.gifClass}>
              <img className={classes.gifImage} src="chrome_ext.gif" alt="Chrome Extension Installation Gif"/>
            </div>
            <div className={classes.companionModalBtns}>
              {" "}
              <Button className={classes.reloadBtn} onClick={() => window.location.reload()}>Reload</Button>
              <Button
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/testrtc-companion/ninnkfgcogbifinffmalccnoijlhhnok",
                    "_blank"
                  )
                }
              >
                Install Extension
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default connect(mapStateToProps, null)(withStyles(styles)(CompanionModal));
