import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { OpenInNewIcon } from "../../../../../assets/icons";

interface Props {
  chartClass: string;
  classes: any;
  titleColor?: string;
  chartTitle?: string;
  config?: any;
  scoringResult?: any;
}

const TestStatusChart = ({ chartClass, classes, titleColor, chartTitle, config, scoringResult }: Props) => {
  const { t } = useTranslation(["tests"]);

  return (
    <>
      {config?.scoreExplainerLink ? (
        <div className={classes.scoreExplainerLink}>
          <Typography variant="h2" className={classes.resultText}>
            {scoringResult ? t("tests:resultPage.scoring-result-title1") : t("tests:resultPage.scoring-result-title2")}
          </Typography>
          <a
            target="_blank"
            href={config?.scoreExplainerLink}
            className={`${classes.btnOverride} ${classes.openNewIcon}`}
            style={{ height: 32 }}
          >
            <OpenInNewIcon />
          </a>
        </div>
      ) : (
        <Typography variant="h2" className={classes.resultText}>
          {t("tests:resultPage.no-scoring-result-title1")}
        </Typography>
      )}
      <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="100" cy="100" rx="95" ry="95" strokeWidth="10px" className={chartClass} />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          className={`${classes.chartTitle} ${titleColor}`}
          fill={titleColor}
        >
          {chartTitle}
        </text>
      </svg>
    </>
  );
};

export default TestStatusChart;
